export function serializeToFormData(
  formData: FormData,
  obj: Record<string, unknown>,
  namespace = ""
): void {
  Object.entries(obj).forEach(([propertyName, propertyValue]) => {
    if (
      propertyName in obj &&
      propertyValue !== undefined &&
      propertyValue !== null &&
      propertyName[0] !== "_"
    ) {
      const formKey = namespace ? `${namespace}.${propertyName}` : propertyName;
      if (Array.isArray(propertyValue)) {
        propertyValue.forEach((element, index) => {
          if (typeof element !== "object") {
            formData.append(formKey, element);
          } else {
            const tempFormKey = `${formKey}.${index}`;
            serializeToFormData(formData, element, tempFormKey);
          }
        });
      } else if (typeof propertyValue === "object") {
        serializeToFormData(
          formData,
          propertyValue as Record<string, unknown>,
          formKey
        );
      } else {
        if (typeof propertyValue === "string") {
          if (propertyValue.length > 0) {
            formData.append(formKey, propertyValue as string | Blob);
          }
        } else {
          formData.append(formKey, propertyValue as string | Blob);
        }
      }
    }
  });
}
