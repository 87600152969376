import { ref } from "vue";
import axios from "axios";
import { UserService } from "@/services/UserService";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

axiosInstance.defaults.withCredentials = true;

const userService = new UserService(axiosInstance);

const userName = ref("");
const userEmail = ref("");

const fetchUser = async (): Promise<Error | null> => {
  const [error, data] = await userService.get();

  if (data?.data) {
    userName.value = `${data.data.Lastname} ${data.data.Firstname} ${data.data.Middlename}`;
    userEmail.value = data.data.Email;
  }

  return error;
};

export function useUser() {
  return {
    fetchUser,
    userName,
    userEmail,
  };
}
