<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import Button from "primevue/button";
import OverlayPanel from "primevue/overlaypanel";
import AppConfig from "@/layout/AppConfig.vue";
import { useLayout } from "@/layout/composables/layout";
import { useUser } from "@/composables/useUser";
import { createBaseUrl } from "@/utils/helpers";

const { onMenuToggle } = useLayout();
const { userName, userEmail } = useUser();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);

onMounted(() => {
  bindOutsideClickListener();
});

onBeforeUnmount(() => {
  unbindOutsideClickListener();
});

const userLogout = ref();
const logoutUrl = createBaseUrl("/login/signout");
const onUserMenuButton = (event) => {
  userLogout.value.toggle(event);
};
const userLogoutHandler = () => {
  location.href = logoutUrl;
};

const onTopBarMenuButton = () => {
  topbarMenuActive.value = !topbarMenuActive.value;
};
const topbarMenuClasses = computed(() => {
  return {
    "layout-topbar-menu-mobile-active": topbarMenuActive.value,
  };
});

const bindOutsideClickListener = () => {
  if (!outsideClickListener.value) {
    outsideClickListener.value = (event) => {
      if (isOutsideClicked(event)) {
        topbarMenuActive.value = false;
      }
    };
    document.addEventListener("click", outsideClickListener.value);
  }
};
const unbindOutsideClickListener = () => {
  if (outsideClickListener.value) {
    document.removeEventListener("click", outsideClickListener);
    outsideClickListener.value = null;
  }
};
const isOutsideClicked = (event) => {
  if (!topbarMenuActive.value) return;

  const sidebarEl = document.querySelector(".layout-topbar-menu");
  const topbarEl = document.querySelector(".layout-topbar-menu-button");

  return !(
    sidebarEl.isSameNode(event.target) ||
    sidebarEl.contains(event.target) ||
    topbarEl.isSameNode(event.target) ||
    topbarEl.contains(event.target)
  );
};
</script>

<template>
  <div class="layout-topbar">
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle()"
    >
      <i class="pi pi-bars"></i>
    </button>

    <router-link to="/" class="layout-topbar-logo w-full">
      <img src="@/assets/img/logo.svg" alt="" />
      <span class="m-auto">Сервис всплывающих окон</span>
    </router-link>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      @click="onTopBarMenuButton()"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>

    <div class="layout-topbar-menu" :class="topbarMenuClasses">
      <button class="p-link layout-topbar-button" @click="onUserMenuButton">
        <i class="pi pi-user"></i>
        <span>Пользователь</span>
      </button>
      <OverlayPanel ref="userLogout">
        <div class="w-17rem">
          <div class="text-xl mb-2">{{ userName }}</div>
          <div class="text-lg text-400 text-gray-600 mb-4">{{ userEmail }}</div>
          <Button label="Выйти" @click="userLogoutHandler" />
        </div>
      </OverlayPanel>
      <app-config />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
