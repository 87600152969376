import { BaseService } from "@/services/BaseService";
import axiosInstance from "@/api/axiosInstance";
import type {
  AxiosInstance,
  PagedStatusDataResponse,
  StatusDataResponse,
} from "@/api/types";
import type { ProjectItemType } from "@/types/ProjectType";

type GetProjectSuccess = PagedStatusDataResponse<ProjectItemType>;
type AddProjectSuccess = StatusDataResponse<ProjectItemType>;
type UpdateProjectSuccess = AddProjectSuccess;

class ProjectService extends BaseService {
  constructor(httpClient: AxiosInstance) {
    super(httpClient);
  }

  getList(params: Record<string, unknown> = {}) {
    const request = this.httpClient.get<GetProjectSuccess>(
      "/admin/project/list",
      {
        params,
      }
    );

    return this.performRequest(request);
  }

  add(data: FormData) {
    const request = this.httpClient.postForm<AddProjectSuccess>(
      "/admin/project/add",
      data
    );

    return this.performRequest(request);
  }

  update(id: string, data: FormData) {
    const request = this.httpClient.postForm<UpdateProjectSuccess>(
      `/admin/project/update/${id}`,
      data
    );

    return this.performRequest(request);
  }

  remove(id: string) {
    const request = this.httpClient.postForm("/admin/project/remove", {
      guid: id,
    });

    return this.performRequest(request);
  }

  toggle(id: string, isActive: boolean) {
    const request = this.httpClient.postForm(`/admin/project/toggle/${id}`, {
      guid: id,
      active: isActive,
    });

    return this.performRequest(request);
  }

  removeUrl(id: string) {
    const request = this.httpClient.postForm("/admin/project/url/remove", {
      guid: id,
    });

    return this.performRequest(request);
  }
}

export default new ProjectService(axiosInstance);
