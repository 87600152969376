import { BaseService } from "@/services/BaseService";
import type { AxiosInstance, StatusDataResponse } from "@/api/types";
import type { User } from "@/types/BasicType";

type UserResponse = StatusDataResponse<User>;

export class UserService extends BaseService {
  constructor(httpClient: AxiosInstance) {
    super(httpClient);
  }

  get() {
    const request = this.httpClient.get<UserResponse>("/admin/user/get");

    return this.performRequest(request);
  }
}
