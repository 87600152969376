import axios from "axios";
import { generateResponseError } from "@/api/utils";
import type { AxiosError, AxiosInstance } from "@/api/types";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      location.href = "/login";
    }

    const err = generateResponseError(error);

    console.error(err.message);

    return Promise.reject(err);
  }
);

export default axiosInstance;
