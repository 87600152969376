import type { ProjectModelType } from "@/types/ProjectType";

export class ValidationError extends Error {
  public index?: number;

  constructor(message: string, index?: number) {
    super(message);
    this.name = "ValidationError";
    this.index = index;
  }
}

interface FieldErrors {
  name?: ValidationError | null;
  urls?: ValidationError | null;
}

interface ValidationType {
  isValid: boolean;
  errors: FieldErrors;
}

export const validateProject = (project: ProjectModelType): ValidationType => {
  const errors: FieldErrors = {
    name: null,
    urls: null,
  };

  if (!project.Name) {
    errors.name = new ValidationError("Укажите название проекта");
  } else if (project.Name.length < 3) {
    errors.name = new ValidationError(
      "Название проекта должно содержать не менее 3 символов"
    );
  }

  if (
    project.Urls.length < 1 ||
    (project.Urls.length === 1 && !project.Urls[0].Url)
  ) {
    errors.urls = new ValidationError("Укажите хотя бы одну ссылку", 0);
  } else {
    const invalidIndex = project.Urls.findIndex((url) => url.Url.length < 3);

    if (invalidIndex !== -1) {
      errors.urls = new ValidationError(
        "Ссылка должна содержать не менее 3 символов",
        invalidIndex
      );
    }
  }

  return {
    isValid: !errors.name && !errors.urls,
    errors,
  };
};
