import { useToast } from "primevue/usetoast";

type ToastSeverity = "success" | "warn" | "error";

type ToastFunction = (message: string, title?: string, time?: number) => void;

export const useCustomToast = () => {
  const toast = useToast();

  const showToast = (
    severity: ToastSeverity,
    message: string,
    title?: string,
    time = 3000
  ) => {
    let defaultTitle: string;

    switch (severity) {
      case "success":
        defaultTitle = "Успешно";
        break;
      case "warn":
        defaultTitle = "Предупреждение";
        break;
      case "error":
        defaultTitle = "Ошибка";
        break;
      default:
        defaultTitle = "Уведомление";
    }

    toast.add({
      severity,
      summary: title || defaultTitle,
      detail: message,
      life: time,
    });
  };

  const showSuccess: ToastFunction = (message, title, time) =>
    showToast("success", message, title, time);

  const showWarning: ToastFunction = (message, title, time) =>
    showToast("warn", message, title, time);

  const showError: ToastFunction = (message, title, time) =>
    showToast("error", message, title, time);

  return {
    showSuccess,
    showWarning,
    showError,
  };
};
