<script setup lang="ts">
import { onBeforeMount } from "vue";
import { useUser } from "@/composables/useUser";
import { useProjectStore } from "@/store/projectStore";
import { useCustomToast } from "./composables/useCustomToast";

const projectStore = useProjectStore();
const { fetchProjectsList } = projectStore;
const { fetchUser } = useUser();
const { showError } = useCustomToast();

const handleFetchProjects = async () => {
  const error = await fetchProjectsList();

  if (error) showError(error.message);
};

const hanldeFetchUser = async () => {
  const error = await fetchUser();

  if (error) {
    showError(error.message);
    return;
  }

  await handleFetchProjects();
};

onBeforeMount(hanldeFetchUser);
</script>

<template>
  <router-view />
</template>

<style lang="scss"></style>
