import { createApp } from "vue";
import { createPinia } from "pinia";
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import App from "./App.vue";
import router from "./router";
import "./assets/styles.scss";

createApp(App)
  .use(PrimeVue, { ripple: true })
  .use(router)
  .use(ToastService)
  .use(ConfirmationService)
  .use(createPinia())
  .directive("tooltip", Tooltip)
  .mount("#app");
