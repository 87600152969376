import type { AxiosError } from "@/api/types";

export const generateResponseError = (error: AxiosError) => {
  let errorMessage = "Произошла непредвиденная ошибка";

  if (error.response?.data?.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  }

  return new Error(errorMessage, { cause: error });
};
